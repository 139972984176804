<div *ngIf="items$ | async as items" class="generic-listbox-wrapper sl-flexbox-column sl-flexbox-gap-4" >
    <label *ngIf="configSignal$().ui.label" class="label-toggle sl-flexbox-row sl-flexbox-justify-space-between sl-flexbox-align-center sl-typography-label">
        {{ configSignal$().ui.label }}
        @if (configSignal$()?.labelToggle) {
			<span class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-gap-8">
				<mat-icon class="mat-icon-16" [matTooltip]="configSignal$().labelToggle.iconTooltip">{{ configSignal$().labelToggle.icon }}</mat-icon>
				<ng-container>{{ configSignal$().labelToggle.label }}</ng-container>
			</span>
			<p-inputSwitch [ngModel]="configSignal$().labelToggle.default" class="input-switch-small" (onChange)="onLabelToggleChange($event)"></p-inputSwitch>
		}
    </label>
	<div class="sl-flexbox-column">
	@switch (configSignal$()?.ui?.displayMode) {
		@case ('list') {
			<p-listbox #listbox
				styleClass="new-design no-border sl-v1"
				optionLabel="label"
				[listStyle]="{ 'max-height': '220px' }"
				[options]="items"
				[ngModel]="selectedItems"
				[filter]="configSignal$()?.ui.filter"
				[multiple]="configSignal$()?.ui.multiSelect"
				[checkbox]="true"
				[showToggleAll]="true"
				(onClear)="clearFilter(true)"
				(onSelectAllChange)="onSelectAllChange($event, items)"
				(onChange)="onSelectionChange($event, items)">
				<ng-template pTemplate="filter">
					<span class="p-input-icon-right sl-width-100">
						<i class="pi {{!!loadingSignal$() ? 'pi-spin pi-spinner' : 'pi-search'}}"></i>
						<input class="sl-v1" placeholder="Search..." type="text" pInputText [formControl]="searchControl" />
					</span>
				</ng-template>
				<ng-template let-item pTemplate="item">
					<ng-container *ngTemplateOutlet="itemDisplay; context:{ item, items }"></ng-container>
				</ng-template>
				<ng-template pTemplate="empty">
					<ng-container *ngTemplateOutlet="emptyMessage"></ng-container>
				</ng-template>
			</p-listbox>
		}

		@case ('dropdown') {
			<p-dropdown #dropdown
				[styleClass]="'sl-v1-dropdown ' + (configSignal$()?.ui?.isFirst ? '' : 'remove-border')"
				panelStyleClass="sl-v1-dropdown-panel"
				optionLabel="label"
				appendTo="body"
				[options]="items"
				[showClear]="!!selectedItems.length"
				[disabled]="disabled"
				[autoDisplayFirst]="false"
				[placeholder]="configSignal$()?.ui.placeholder"
				[ngModel]="selectedItems?.[0]"
				[filter]="configSignal$()?.ui.filter"
				(onClear)="clearFilter(true)"
				(onFilter)="searchControl.setValue($event.filter)"
				(onChange)="onSelectionChange($event, items)">
				<ng-template let-item pTemplate="item">
					<ng-container *ngTemplateOutlet="itemDisplay; context:{ item, items }"></ng-container>
				</ng-template>
				<ng-template pTemplate="clearicon">
					<i class="pi pi-times clearicon"></i>
				</ng-template>
				<ng-template pTemplate="dropdownicon">
					<i class="pi pi-angle-down"></i>
				</ng-template>
				<ng-template pTemplate="empty">
					<ng-container *ngTemplateOutlet="emptyMessage"></ng-container>
				</ng-template>
			</p-dropdown>
		}

		@case ('multiselect') {
			<p-multiSelect #multiselect
				styleClass="sl-v1-multiselect"
				panelStyleClass="sl-v1-multiselect-panel"
				optionLabel="label"
				[filter]="configSignal$().ui.filter"
				[showToggleAll]="true"
				[autofocusFilter]="true"
				[options]="items"
				[placeholder]="configSignal$().ui.placeholder"
				[(ngModel)]="selectedItems"
				[disabled]="disabled"
				(onClear)="clearFilter(true)"
				(onFilter)="searchControl.setValue($event.filter)"
				(onSelectAllChange)="onSelectAllChange($event, items)"
				(onChange)="onSelectionChange($event, items)">
				<ng-template let-item pTemplate="item">
					<ng-container *ngTemplateOutlet="itemDisplay; context:{ item, items }"></ng-container>
				</ng-template>
				<ng-template pTemplate="clearicon">
					<i class="pi pi-times clearicon"></i>
				</ng-template>
				<ng-template pTemplate="dropdownicon">
					<i class="pi pi-angle-down"></i>
				</ng-template>
				<ng-template pTemplate="empty">
					<ng-container *ngTemplateOutlet="emptyMessage"></ng-container>
				</ng-template>
			</p-multiSelect>
		}
	}
	</div>
</div>

<ng-template #itemDisplay let-item="item" let-items="items">
	<div class="sl-typography-body sl-flexbox-row sl-flexbox-align-center item-label-wrapper"
		[class.dropdown-item]="configSignal$()?.ui?.displayMode === 'dropdown'"
		[class.selected]=" !configSignal$()?.ui.multiSelect && selectedItems?.[0]?.value == item.value">

		<div class="filter-options sl-flexbox sl-width-100">
			<div class="filter-label ellipsisize sl-typography-body-medium" tooltipStyleClass="sl-v1-tooltip" [pTooltip]="item.label">{{ item.label }}</div>
			<div *ngIf="item.count" class="filter-count sl-typography-body-medium">{{ item.count | number }}</div>
			<span *ngIf="configSignal$()?.ui?.displayMode === 'multiSelect' || configSignal$()?.ui?.multiSelect" class="sl-typography-link-action only" (click)="selectOnly()">Only</span>
		</div>
	</div>
</ng-template>

<ng-template #emptyMessage>
	<ng-container *ngIf="loadingSignal$()">
		<i class="pi pi-spin pi-spinner"></i>
	</ng-container>
	<ng-container *ngIf="!loadingSignal$()">No results found</ng-container>
</ng-template>
