import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { SLTableColumnDef, SLTableFilterTypeConfig, SLTableOptionsTypeConfig } from '@@shared/sl-table/models/sl-table.model';

@Directive({
	standalone: true
})
export abstract class BaseFilterDirective<Req, Res, F extends SLTableFilterTypeConfig<Req, Res>, O extends SLTableOptionsTypeConfig = SLTableOptionsTypeConfig> implements ControlValueAccessor {
	@Input() column: SLTableColumnDef<Req, Res, F, O>;

	writeValue(obj: any): void { }
	registerOnChange(fn: any): void { }
	registerOnTouched(fn: any): void { }
	setDisabledState?(isDisabled: boolean): void { }
}
