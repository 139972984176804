import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Condition, IslQueryRuleSet, Operator, SlQueryBaseRule, SlQueryRequestBuilderWeb } from '@sealights/sl-query-builder';
import { startOfDay, subYears } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { tap } from 'rxjs';

import { PopularPeriod } from '@@core/models/utils/popular-period.model';
import { PopularPeriodService } from '@@core/services/utils/popular-period.service';
import { DataTestidDirective } from '@@shared/data-test-id-directive';
import { DateFilterConfig } from '@@shared/sl-table/models/sl-table.model';

import { CUSTOM_TIA_PERIOD_NAME, popularTiaPeriods } from '../../../../../features/tia/models/tia.model';
import { BaseFilterDirective } from '../base-filter/base-filter.directive';


@Component({
	selector: 'sl-date-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DropdownModule,
		DataTestidDirective,
		MatIconModule,
		CalendarModule,
		MatDatepickerModule,
		NativeDateModule,
		ButtonModule,
		CascadeSelectModule,
		MatButtonModule,
		ListboxModule,
		MatInputModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DateFilterComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: DateFilterComponent,
			multi: true
		}
	],
	templateUrl: './date-filter.component.html',
	styleUrl: './date-filter.component.scss'
})
export class DateFilterComponent<Req, Res> extends BaseFilterDirective<Req, Res, DateFilterConfig> implements OnInit, Validator {
	readonly calendar = viewChild.required<Calendar>(Calendar);

	popularPeriods: PopularPeriod[];
	selectedPopularTiaPeriod: PopularPeriod;
	showCalendar: boolean = false;

	readonly factoryPopularTiaPeriods = popularTiaPeriods();
	readonly maxDate = new Date();
	readonly minDate = startOfDay(subYears(new Date(), 1));
	readonly periodFormControl = new FormControl<PopularPeriod>(null);
	readonly customDateRangeControl = new FormControl<Date[]>([]);

	readonly #destroyRef = inject(DestroyRef);
	readonly #popularPeriodService = inject(PopularPeriodService);
	readonly #cdr = inject(ChangeDetectorRef);

	#onChange: (value: IslQueryRuleSet<Req, Res> | null) => void;
	#onTouched: () => void;

	constructor() {
		super();
		this.#composePopularPeriodsDisplayNames();
		this.selectedPopularTiaPeriod = this.popularPeriods[1];
	}

	validate(control: AbstractControl<any, any>): ValidationErrors {
		return null;
	}

	writeValue(ruleSet: IslQueryRuleSet<Req, Res>): void {
		if (ruleSet?.rules?.length > 0) {
			const [fromRule, toRule]: SlQueryBaseRule<Req, Res, any>[] = ruleSet.rules;
			const matchedPeriod = this.popularPeriods.find(({ period }) => period.from === fromRule.value && period.to === toRule.value);
			if (matchedPeriod) {
				this.periodFormControl.setValue(matchedPeriod, { emitEvent: false });
			} else {
				this.#addCustomPeriod(new Date(Number(fromRule.value)), new Date(Number(toRule.value)), false);
				this.#toggleCalendarDisplay(true);
			}
		} else {
			this.periodFormControl.reset(this.popularPeriods[1], { emitEvent: false });
			this.customDateRangeControl.reset([], { emitEvent: false });
			this.#toggleCalendarDisplay(false);
		}
	}

	registerOnChange(fn: any): void {
		this.#onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.#onTouched = fn;
	}

	ngOnInit(): void {
		this.#listenToPeriodControl();
		this.#listenToCustomDateRangeControl();
		this.#cdr.detectChanges();
	}

	periodItemClick(item: PopularPeriod): void {
		if (item.isCustom) {
			this.showCalendar = true;
			setTimeout(() => {
				this.calendar()?.showOverlay();
			}, 500);
		} else {
			this.showCalendar = false;
		}
		this.#cdr.detectChanges();
	}

	dateRangeEndChange(dates: Date[]): void {
		const start = dates[0];
		const end = dates[1];
		if (!!start && !!end) {
			this.#addCustomPeriod(new Date(start), new Date(end));
			// this.dateRangeClosed();
		}
	}

	dateRangeClosed(): void {
		const currentValue = this.periodFormControl.value;

		if (currentValue.name === CUSTOM_TIA_PERIOD_NAME) {
			const period = this.selectedPopularTiaPeriod.period;
			this.selectedPopularTiaPeriod.displayName = this.#popularPeriodService.createPeriod(new Date(period.from), new Date(period.to)).name;

			this.periodFormControl.setValue(this.selectedPopularTiaPeriod, { emitEvent: false });
			this.#toggleCalendarDisplay(false);
		}
		this.#cdr.detectChanges();
	}

	#toggleCalendarDisplay(value: boolean): void {
		if (value) {
			this.showCalendar = true;
			this.calendar()?.showOverlay();
		} else {
			this.showCalendar = false;
			this.calendar()?.hideOverlay();
		}
		this.#cdr.detectChanges();
	}

	#listenToPeriodControl(): void {
		this.periodFormControl.valueChanges
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap((tiaPeriod: PopularPeriod) => {
					if (tiaPeriod.isCustom) {
						this.#toggleCalendarDisplay(true);
					} else {
						this.#removeCustomPeriodItem();
						this.#selectPopularPeriod(tiaPeriod);
					}
				})
			)
			.subscribe();
	}

	#listenToCustomDateRangeControl(): void {
		this.customDateRangeControl.valueChanges
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap((value) => {
					this.dateRangeEndChange(value);
				})
			)
			.subscribe();
	}

	#selectPopularPeriod(popularPeriod: PopularPeriod, emit = true): void {
		this.selectedPopularTiaPeriod = popularPeriod;
		if (emit) {
			this.#onFilterChange(this.selectedPopularTiaPeriod.period);
		} else {
			setTimeout(() => {
				this.periodFormControl.setValue(popularPeriod, { emitEvent: false });
			});
		}
	}

	#addCustomPeriod(start: Date, end: Date, emit = true): void {
		this.selectedPopularTiaPeriod = null;
		const customItem = this.#popularPeriodService.createPeriod(start, end);
		this.#addCustomPeriodItem(customItem);
		this.#selectPopularPeriod(customItem, emit);
	}

	#addCustomPeriodItem(customPeriodItem: PopularPeriod): void {
		this.popularPeriods = [...this.factoryPopularTiaPeriods, customPeriodItem];
	}

	#removeCustomPeriodItem(): void {
		this.popularPeriods = [...this.factoryPopularTiaPeriods];
	}

	#composePopularPeriodsDisplayNames(): void {
		this.factoryPopularTiaPeriods
			.forEach(item => item.displayName = item.isBuiltIn
				? `${item.name} (${this.#popularPeriodService.createPeriod(new Date(item.period.from), new Date(item.period.to)).name})`
				: item.name
			);
		this.popularPeriods = [...this.factoryPopularTiaPeriods];
	}

	#onFilterChange(dates: { from: number; to: number }): void {
		if (!dates) {
			this.#onChange(null);
			return;
		}
		const ruleSet: IslQueryRuleSet<Req, Res> = SlQueryRequestBuilderWeb.createRuleSetBuilder(Condition.And).done();
		ruleSet.rules = [
			{ field: this.column.field, operator: Operator.GreaterThanOrEqualTo, value: dates.from },
			{ field: this.column.field, operator: Operator.SmallerThanOrEqualsTo, value: dates.to }
		];
		this.#onChange(ruleSet);
	}

}
