<div class="sl-width-100">
    <p-dropdown styleClass="sl-v1-dropdown sl-width-100 new-design max-width-unset"
				panelStyleClass="sl-v1-dropdown-panel sl-width-100"
				scrollHeight="208" optionLabel="name"
				[options]="popularPeriods"
				[formControl]="periodFormControl"
				data-testid="date-range-dropdown">
        <ng-template let-item pTemplate="item">
            <div attr.data-testid="data-range-dropdown-item-{{ item.name }}"
                (click)="periodItemClick(item)">
                <div class="sl-typography-body-medium">{{ item.name }}</div>
            </div>
        </ng-template>
        <ng-template let-selectedItem pTemplate="selectedItem">
            <span class="item-name sl-typography-body-medium">{{ selectedItem.displayName }}</span>
        </ng-template>
        <ng-template pTemplate="dropdownicon">
            <mat-icon class="mat-icon-20">calendar_month</mat-icon>
        </ng-template>
    </p-dropdown>
    <div [hidden]="!showCalendar" class="mt-lg">
        <p-calendar [inline]="true"
					styleClass="sl-v1 new-design inline no-border"
					[minDate]="minDate"
					[maxDate]="maxDate"
					[numberOfMonths]="2"
					[formControl]="customDateRangeControl"
					selectionMode="range"
					[readonlyInput]="true"
					(click)="$event.stopPropagation()"
					(onClose)="dateRangeClosed()">
		</p-calendar>
    </div>
</div>
